import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
          PPUZZL Group is a technology research group about simulation, concurrency, AI, 
          economics, politics and literature.
          </p>
          {close}
        </article>

        <article
          id="works"
          className={`${this.props.article === 'works' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Works</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">BBTS @ <a href="https://transactology.org">Transactology</a></h3>
          <p>
            Blockchain Benchmark Test Set is cross-architecture benchmark test set for blockchain
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major"><a href="https://simulai.dev">SimulAI</a> @ <a href="https://senggack.com">SengGack</a></h3>
          <p>
            SimulAI is spry and reasonable AI for simulation
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major"><a href="https://completengine.com">CoMPleT Engine</a> @ <a href="https://holos.works">Holos Works</a></h3>
          <p>
            CoMPleT Engine is concurrent script interpreting engine for simulation
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">ArchCEREScript / ParaGram @ <a href="https://holos.works">Holos Works</a></h3>
          <p>
            ArchCEREScript and ParaGram are script languages for concurrent/parallel simulation.
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major"><a href="https://storiographer.com">StorioGrapher</a> @ <a href="https://holos.works">Holos Works</a></h3>
          <p>
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">mbComparison @ A6C</h3>
          <p>
            <b>mbComparison</b> is sequence comparison algorithm
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/PPUZZL"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://ppuzzl.com" className="icon fa-home">
                <span className="label">PPUZZL Group</span>
              </a>
            </li>
            <li>
              <a
                href="https://gitlab.com/PPUZZL"
                className="icon fa-gitlab"
              >
                <span className="label">GitLab</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/PPUZZL"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
